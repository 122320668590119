import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../components/breadcrumb/index'
export default function MailSetting() {
    const title = "General Setting"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    
    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {
       
    //    call api
        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])


    const updateSetting = () => {

        Swal.fire({
            icon: 'info',
            title: 'Are You Want to take this action',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
             
                Swal.fire('Update success', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                       
                    </div>
                    <div className="card-body">
                        <div  className="row justify-content-center">
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Title</ b></label>
                                <input type="text" placeholder="Enter Your Title" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>TimeZone</ b></label>
                                <select className="form-control">
                                    <option value="">Choose Time Zone</option>
                                    <option value="">Usd Time Zone</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Currency</ b></label>
                                <select className="form-control">
                                    <option value="usd">USD</option>
                                    
                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Date Formate</ b></label>
                                <select className="form-control">
                                    <option value="yes">MM/DD/YYYY</option>
                                    <option value="yes">DD/MM/YYYY</option>                                   
                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Email</ b></label>
                                <input type="text" placeholder="Enter Email" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Phone</ b></label>
                                <input type="text" placeholder="Enter Phone Number" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Address</ b></label>
                                <input type="text" placeholder="Enter Address" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label  ><b>Product Image</ b></label>
                                <input type="file"  className="form-control" />
                            </div>
                            
                        </div>
                        <button onClick={updateSetting} className="btn btn-primary btn-sm mt-3">Submit</button>
                    </div>
                </div>
            </div>

        </>
    )
}