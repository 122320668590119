import { useState } from "react";
import Breadcrumb from "../../components/breadcrumb/index";

const UpdateBanner = () => {
    const [Banner,setBanner] = useState([''])
    const AddBanner = function() {
        
    }
    const title = "Banner";
    const brad = [
        {
            name: "Home",
        },
        {
            name: title,
        }
    ]
  return (
    <>
        <div className='container-fluid'>
            <Breadcrumb title={title} brad={brad} />
        </div>
        <div className='container-fluid'>
            <div className='card' style={{borderTop: '2px solid #4723d9'}}>
                <div className="card-header d-flex justify-content-between border-bottom pb-1">
                    <h4>{title}</h4>
                    <button onClick={AddBanner} className='btn btn-primary'>+ Add</button>
                </div>
            </div>
        </div>
    </>
  )
}

export default UpdateBanner