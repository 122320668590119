import { Link } from 'react-router-dom'
export default function Pay_setting_btn_grp({active_btn,btn_link}) {
    return (
        <>
            <div className="mb-2 my-3">
                <Link to={btn_link.okwallet} ><button type="button" className={`btn mt-2 btn-outline-secondary ${active_btn == "okWallet" ? "active": " " } btn-sm ms-1`}>OK Wallet</button></Link>
                <Link to={btn_link.apimethod} ><button type="button" className={`btn mt-2 btn-outline-danger ${active_btn == "api" ? "active": " " } btn-sm ms-1`}>API Method</button></Link>
                  
            </div>
        </>
    )
}