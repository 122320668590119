import {useState} from 'react'
import Hero from './hero'
import '../../App.css'
import './dashboard.css'

export default function Dashboard() {

    return (      
        < Hero />
    )
}