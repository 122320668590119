import { useState, useEffect } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import DataTable_Component from '../../components/DataTable/index'
import Btn_grp from '../../components/bankPayment/payment_btn_grp/index'
import { apidata } from '../payment/store/store'
import Status_modal from '../../components/payment/status_modal/index'
import Details_modal from '../../components/payment/details_modal/index'
import { btn_link } from "../payment/btn_link/btn_link";

export default function Pending() {

    const title = "Pending payments"
    // data store
    const [data, setData] = useState([])
    // column name
    const [columnState, setcolumns] = useState([])
    // select modal value
    const [selectVal, setSelectval] = useState([])
    // pass status model render
    const [openModal, setOpenModal] = useState(true)
    {/* all data for details modal */ }
    const [selectVal_details, setSelectVal_details] = useState([])
    {/* show details modal(view) */ }
    const [details_modal_show, set_details_modal_Show] = useState(false);
    const [view_id, setViewId] = useState();
    const [rerendarApi, setRerendarApi] = useState(true)


    // show view modal;
    const handleShow_view_modal = (row) => {
        setViewId(row.id)
        setSelectVal_details(row)
        set_details_modal_Show(details_modal_show => !details_modal_show);
    }
    // end view modal config

    // api data from store file 
    useEffect(() => {
         // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )
        setData(apidata)
        // column state
        setcolumns(columns)
        console.log('render from payment pending')
    }, [rerendarApi])
    // api data filteing
    const pending_data = data.filter((val) => {
        return val.status?.toLowerCase() == "pending"
    })

    const rerender = (e) => {
        setRerendarApi(!rerendarApi)
    }

    const brad = [
        {
            name: "home",
        },
        {
            name: "pending-payments",
        }
    ]

    const columns = [
        {
            name: "Brand",
            selector: row => row.brand,
            sortable: true
        },
        {
            name: "Full Name",
            selector: row => row.name,
            sortable: true
        },
        {
            name: "Payment Method",
            selector: row => row.payment_method,
            sortable: true
        },
        {
            name: "Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Charged Amount",
            selector: row => row.amount,
            sortable: true
        },
        {
            name: "Transaction Id",
            selector: row => row.transaction_id,
            sortable: true
        },
        {
            name: "Date",
            selector: row => row.date,
            sortable: true
        },

        {
            name: "action",
            cell: (row) => <><button onClick={() => handleShow_view_modal(row)} className=" btn btn-primary btn-sm"  >view</button></>,
        }

    ]


    return (
        <>

            {/* status modal component */}

            <Details_modal rerendar={(e) => rerender(e)} view_id={view_id} row={selectVal_details} openDetailsModal={details_modal_show} />
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Btn_grp active_btn="pending" btn_link={btn_link} />

                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>

                            </div>
                            <div className="card-body">

                                <DataTable_Component search="name"  title={title} apidata={pending_data} columns={columnState} />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}