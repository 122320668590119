import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        name: "upay",
        min_amount:34,
        max_amount:34,
        status: "active",

    },
    {
        id:2,
        brand: "onhost",
        name: "paoyneer",
        min_amount:34,
        max_amount:34,
        status: "inactive",

    },
  {
        id:21,
        brand: "onhost",
        name: "paddle",
        min_amount:34,
        max_amount:74,
        status: "active",
    },
  

]
