import React from "react";
import Chart from "react-apexcharts";
import Charts2 from "react-apexcharts";

const AreaChart = () => {
  const options = {
    chart: {
      type: "area",
    },

    stroke: {
      width: [1, 2],
      curve: "smooth",
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };
  const series = [
    {
      name: "Revenue",
      data: [30, 40, 45, 60, 49, 60, 80, 91, 125],
    },
    {
      name: "Cost",
      data: [20, 60, 35, 30, 49, 60, 70, 81, 115],
    },
  ];

  return (
    <>
      <Chart options={options} series={series} type="area" height={320} />
    </>
  );
};

const DonutChart = () => {
  var series = [44, 55, 41];
  var options = {
    labels: ["Payment", "Stored Data", "Unpaid Invoice"],
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
          },
        },
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: "gradient",
    },

    legend: {
      position: "bottom",
    },
  };

  return (
    <>
      <Charts2 options={options} series={series} type="donut" height={350} />
    </>
  );
};

export { AreaChart, DonutChart };
