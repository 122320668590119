import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { apidata } from './store/store';
import Breadcrumb from '../../components/breadcrumb/index';
import loaderImg from '../../assets/photo/admin.jpg';


const User_Data = () => {
    const { id } = useParams();
    const [fields, setFields] = useState({});
    const { storeData } = apidata();
    const [apistate, setApiState] = useState({});

    useEffect(() => {
        const data = storeData.find(d => d?.id === Number(id));
        if (data) {
            setFields(data);
            setApiState(data);
        }
    }, [id, storeData]);

    const title = 'User';
    const brad = [
        { name: 'Home' },
        { name: title },
    ];

    const handleCardChange = (index, key, value) => {
        setFields(prevFields => {
            const updatedCards = [...prevFields.card];
            updatedCards[index] = { ...updatedCards[index], [key]: value };
            return { ...prevFields, card: updatedCards };
        });
    };

    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        if (file) {
            setFields(prevFields => ({
                ...prevFields,
                [field]: URL.createObjectURL(file)
            }));
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <Breadcrumb title={title} brad={brad} />
                <div className='mb-2 my-3'>
                    <Link to={`/user/${id}`}>
                        <button type='button' className='btn mt-2 btn-outline-info btn-sm '>User</button>
                    </Link>
                    <Link to={`/account/${id}`}>
                        <button type='button' className='btn mt-2 btn-outline-success btn-sm ms-1'>Bank</button>
                    </Link>
                </div>

                <div className='card' style={{ borderTop: '2px solid #4723d9' }}>
                    <div className='card-header d-flex justify-content-between border-bottom pb-1'>
                        <h4>User Details</h4>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.name || ''}
                                    onChange={e => setFields({ ...fields, name: e.target.value })}
                                    placeholder='User Name'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.mobile || ''}
                                    onChange={e => setFields({ ...fields, mobile: e.target.value })}
                                    placeholder='Mobile Number'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.email || ''}
                                    onChange={e => setFields({ ...fields, email: e.target.value })}
                                    placeholder='Email Address'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.dob || ''}
                                    onChange={e => setFields({ ...fields, dob: e.target.value })}
                                    placeholder='Date Of Birth'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <h3>Avtaar</h3>
                                <img src={ fields?.avtaar || loaderImg} style={{width:'100%',height: '100px',objectFit:'contain',margin: '20px auto'}} />
                                <input
                                    className='form-control'
                                    onChange={e => setFields({ ...fields, avtaar: e.target.value })}
                                    type='file'
                                />
                            </div>
                            <div className='col-md-12 my-3 text-end'>
                                <button className='btn' style={{ background: '#4723d9', color: '#fff' }}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card' style={{ borderTop: '2px solid #4723d9' }}>
                    <div className='card-header d-flex justify-content-between border-bottom pb-1'>
                        <h4>Bank Details</h4>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.bank_name || ''}
                                    onChange={e => setFields({ ...fields, bank_name: e.target.value })}
                                    placeholder='Bank Name'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.bank_account_no || ''}
                                    onChange={e => setFields({ ...fields, bank_account_no: e.target.value })}
                                    placeholder='Account Number'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.bank_swiftcode || ''}
                                    onChange={e => setFields({ ...fields, bank_swiftcode: e.target.value })}
                                    placeholder='SWIFT Code'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    value={fields?.bank_address || ''}
                                    onChange={e => setFields({ ...fields, bank_address: e.target.value })}
                                    placeholder='Bank Address'
                                    type='text'
                                />
                            </div>
                            <div className='col-md-12 my-3 text-end'>
                                <button className='btn' style={{ background: '#4723d9', color: '#fff' }}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card' style={{ borderTop: '2px solid #4723d9' }}>
                    <div className='card-header d-flex justify-content-between border-bottom pb-1'>
                        <h4>Card Details</h4>
                        <button className='btn' style={{ background: '#4723d9', color: '#fff' }}><i className='fa fa-plus'></i> Add</button>
                    </div>
                    <div className='card-body'>
                        {fields?.card?.map((el, index) => (
                            <div className='row my-3' key={index}>
                                <div className='col-md-3 my-3'>
                                    <input
                                        className='form-control'
                                        value={el?.type || ''}
                                        onChange={e => handleCardChange(index, 'type', e.target.value)}
                                        placeholder='Card Type'
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-3 my-3'>
                                    <input
                                        className='form-control'
                                        value={el?.no || ''}
                                        onChange={e => handleCardChange(index, 'no', e.target.value)}
                                        placeholder='Card Number'
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-3 my-3'>
                                    <input
                                        className='form-control'
                                        value={el?.validity || ''}
                                        onChange={e => handleCardChange(index, 'validity', e.target.value)}
                                        placeholder='Card Validity'
                                        type='text'
                                    />
                                </div>
                                <div className='col-md-3 my-3'>
                                    <input
                                        className='form-control'
                                        value={el?.cvv || ''}
                                        onChange={e => handleCardChange(index, 'cvv', e.target.value)}
                                        placeholder='Card CVV Number'
                                        type='text'
                                    />
                                </div>
                            </div>
                        ))}
                        <div className='col-md-12 my-3 text-end'>
                            <button className='btn' style={{ background: '#4723d9', color: '#fff' }}>Update</button>
                        </div>
                    </div>
                </div>
                <div className='card' style={{ borderTop: '2px solid #4723d9' }}>
                    <div className='card-header d-flex justify-content-between border-bottom pb-1'>
                        <h4>Account KYC Details</h4>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-3 my-3'>
                                <input
                                    className='form-control'
                                    placeholder='Id Number'
                                    value={fields?.verificationId || ''}
                                    onChange={e => setFields({ ...fields, verificationId: e.target.value })}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-4 my-3'>
                                <h3>Id Front Photo</h3>
                                <img src={fields?.idFrontPhoto || loaderImg} style={{width:'100%',height:'150px',objectFit:'contain',margin:'20px auto'}} alt='SSN' />
                                <input
                                    type='file'
                                    onChange={e => handleFileChange(e, 'idFrontPhoto')}
                                />
                            </div>
                            <div className='col-md-4 my-3'>
                                <h3>Id Back Photo</h3>
                                <img src={fields?.idBackPhoto || loaderImg} style={{width:'100%',height:'150px',objectFit:'contain',margin:'20px auto'}} alt='SSN' />
                                <input
                                    type='file'
                                    onChange={e => handleFileChange(e, 'idBackPhoto')}
                                />
                            </div>
                            <div className='col-md-4 my-3'>
                                <h3>SSN Photo</h3>
                                <img src={fields?.SSNPhoto || loaderImg} style={{width:'100%',height:'150px',objectFit:'contain',margin:'20px auto'}} alt='SSN' />
                                <input
                                    type='file'
                                    className='form-control'
                                    onChange={e => handleFileChange(e, 'SSNPhoto')}
                                />
                            </div>
                            <div className='col-md-12 my-3 text-end'>
                                <button className='btn' style={{ background: '#4723d9', color: '#fff' }}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default User_Data;