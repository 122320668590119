import {Route,Routes} from 'react-router-dom';

import Dashboard from '../pages/dashboard/index'
// Auth
import Login from '../pages/Auth/login'
import Register from '../pages/Auth/register'
import Forgot_password from '../pages/Auth/forgot_password'
// Profile setting
import Profile from '../pages/profileSetting/index'

// payment
import PaymentsAllCom from '../pages/payment/index'
import PendingPayments from '../pages/payment/Pending'
import TrashPayment from '../pages/payment/Trash'
import RefundPayment from '../pages/payment/Refund'
// bank payment
import BankPaymentsAllCom from '../pages/bankPayment/index'
import BankPendingPayments from '../pages/bankPayment/Pending'
import BankTrashPayment from '../pages/bankPayment/Trash'
import BankRefundPayment from '../pages/bankPayment/Refund'
// stored data
import StoredDataAll from '../pages/storedData/index'
import StoredDataTrashAll from '../pages/storedData/Trash'
// Manage Invoice
import ManageInvoice from '../pages/manageInvoice/index'
import AddInvoice from '../pages/manageInvoice/add_invoice'
import InvoiceLink from '../pages/manageInvoice/invoice_link'
// Brand setting
import ManageBrand from '../pages/manageBrand/index'
import AddBrand from '../pages/manageBrand/add_brand'
import EditBrand from '../pages/manageBrand/edit_brand'
import ManageFAQ from '../pages/manageBrand/manage_faq'
import AddFAQ from '../pages/manageBrand/add_faq'
import EditFAQ from '../pages/manageBrand/edit_faq'

// user Setting
import UserList from '../pages/user/user_list';


// payment setting
import Bkash_pay_setting from '../pages/paymentSetting/mobailBank/bkash' 
import Cellfin_pay_setting from '../pages/paymentSetting/mobailBank/cellfin' 
import Ipay_pay_setting from '../pages/paymentSetting/mobailBank/ipay' 
import Nagad_pay_setting from '../pages/paymentSetting/mobailBank/nagad' 
import Okwallet_pay_setting from '../pages/paymentSetting/mobailBank/okWallet' 
import Rocket_pay_setting from '../pages/paymentSetting/mobailBank/rocket' 
import Tap_pay_setting from '../pages/paymentSetting/mobailBank/tap' 
import Upay_pay_setting from '../pages/paymentSetting/mobailBank/upay' 
// mobail bang api
import Mobail_bank_api_method from '../pages/paymentSetting/mobailBank/apiMethod/index' 
import Add_mobail_bankApi from '../pages/paymentSetting/mobailBank/apiMethod/add_api_method' 
import Edit_mobail_bankApi from '../pages/paymentSetting/mobailBank/apiMethod/edit_api_method' 
// Bank Transfer
import ManageBankTransfer from '../pages/paymentSetting/bankTransfer/index' 
import Add_BankTransfer from '../pages/paymentSetting/bankTransfer/add_bank' 
import Edit_BankTransfer from '../pages/paymentSetting/bankTransfer/edit_bank' 
// International_payment
import International_pay_paypal from '../pages/paymentSetting/international/paypal' 
import International_pay_paddle from '../pages/paymentSetting/international/paddle' 
import International_pay_paypal_personal from '../pages/paymentSetting/international/paypal_personal' 
import International_pay_perfectMoney from '../pages/paymentSetting/international/perfectMoney' 
import International_pay_stripe from '../pages/paymentSetting/international/stripe' 
// International_payment manual
import International_pay_Manual from '../pages/paymentSetting/international/manualMethod/index' 
// Addons
import Bulk_sms_bd from '../pages/addons/sms_setting/bulkSmsBd'
import Mim_sms from '../pages/addons/sms_setting/mimSms'
import Sms_q from '../pages/addons/sms_setting/smsq'
import Alpha from '../pages/addons/sms_setting/alpha'
import Sms_transaction from '../pages/addons/sms_transaction/index'

// System Setting
import AppSetting from '../pages/systemSetting/appSetting/index'  
import Templete from '../pages/systemSetting/templete/index' 
import CustomCss from '../pages/systemSetting/customCss/index' 
import Mail from '../pages/systemSetting/mail/index' 
import ActiveLicense from '../pages/systemSetting/license/index' 
// notification
import OnSignal from '../pages/systemSetting/onSignal/index' 
import NotifyEmail from '../pages/systemSetting/email/index' 
// Roll Management
import Roll_user from '../pages/roleManagement/index'
import Roll_edit from '../pages/roleManagement/editRoll'
import Roll_access from '../pages/roleManagement/RoleAccess'
// theme market
import Theme_market from '../pages/Them_Market/index'
import Theme_live_preview from '../pages/Them_Market/live_preview'
// change Password
import ChangePassword from '../pages/changePassword/index' 

// Activities
import Activities from '../pages/activities/index' 
// General Setting
import General_setting from '../pages/generalSetting/index' 
import User_Data from '../pages/user/user-data';
import BankData from '../pages/user/bank-data';
import UpdateBanner from '../pages/banner';

export  function AdminRouteq(){


    return (
        <>  
            <Routes>
                <Route path="/" element={<Dashboard  />} />
                {/* Auth */}
                <Route path="/login" element={<Login  />} />
                <Route path="/register" element={<Register  />} />
                <Route path="/forgot-password" element={<Forgot_password  />} />
                {/* profile */}
                <Route path="/profileSetting" element={<Profile  />} />
              
                {/* payment */}
                <Route path="/payment" element={<PaymentsAllCom />} />
                <Route path="/pending-payment" element={<PendingPayments />} /> 
                <Route path="/trash-payment" element={<TrashPayment />} /> 
                <Route path="/refund-payment" element={<RefundPayment />} /> 
                {/* bank payment */}
                {/* <Route path="/bank-payment" element={<BankPaymentsAllCom />} /> */}
                {/* <Route path="/bank-pending-payment" element={<BankPendingPayments />} />  */}
                {/* <Route path="/bank-trash-payment" element={<BankTrashPayment />} />  */}
                {/* <Route path="/bank-refund-payment" element={<BankRefundPayment />} />  */}
                {/* stored data */}
                <Route path="/stored-data" element={<StoredDataAll />} />
                <Route path="/stored-data-trash" element={<StoredDataTrashAll />} />
                {/* Manage invoice */}
                <Route path="/manage-invoice" element={<ManageInvoice />} />
                <Route path="/add-invoice" element={<AddInvoice />} />
                <Route path="/invoice-link" element={<InvoiceLink />} />
                <Route path="/update-banner" element={<UpdateBanner />} />
                {/* Brand Setting */}
                <Route path="/add-brand" element={<AddBrand />} />
                <Route path="/edit-brand/:id" element={<EditBrand />} />
                <Route path="/manageFaq" element={<ManageFAQ />} />
                <Route path="/addFaq" element={<AddFAQ />} />
                <Route path="/editFaq/:id" element={<EditFAQ />} />
                {/* user Setting */}
                <Route path="/user-list" element={<UserList />} />
                <Route path="/user/:id" element={<User_Data />} />

                <Route path='/account/:userid' element={<BankData />} />

                {/* payment setting */}
                <Route path="/mobailBank/okWallet" element={<Okwallet_pay_setting />} />
                        {/* mobail bank api  */}
                        {/* add-mobailBankApi */}
                <Route path="/mobail-bank-api" element={<Mobail_bank_api_method />} />
                <Route path="/add-mobailBankApi" element={<Add_mobail_bankApi />} />
                <Route path="/edit-mobailBankApi/:id" element={<Edit_mobail_bankApi />} />
                {/* bank Transfer */}
                <Route path="/manage-bankTransfer" element={<ManageBankTransfer />} />
                <Route path="/bankTransfer/add-bank" element={<Add_BankTransfer />} />
                <Route path="/bankTransfer/edit-bank/:id" element={<Edit_BankTransfer />} />
                {/* international payment setting */}
                <Route path="/international-payment/paypal" element={<International_pay_paypal />} />
                <Route path="/international-payment/paddle" element={<International_pay_paddle />} />
                <Route path="/international-payment/paypal_personal" element={<International_pay_paypal_personal />} />
                <Route path="/international-payment/perfectMoney" element={<International_pay_perfectMoney />} />
                <Route path="/international-payment/stripe" element={<International_pay_stripe />} />
                {/* international manual payment setting */}
                <Route path="/manage-international-method" element={<International_pay_Manual />} />
                {/* Sms Transaction */}
                <Route path="/sms-setting/bulksmsbd" element={<Bulk_sms_bd />} />
                <Route path="/sms-setting/mimsms" element={<Mim_sms />} />
                <Route path="/sms-setting/smsq" element={<Sms_q />} />
                <Route path="/sms-setting/alpha" element={<Alpha />} />
                <Route path="/sms-transaction" element={<Sms_transaction />} />
                {/* system setting */}
                <Route path="/system-setting/app-setting" element={<AppSetting />} />             
                <Route path="/system-setting/templete" element={<Templete />} />
                <Route path="/system-setting/custom-css" element={<CustomCss />} />
                <Route path="/system-setting/mail" element={<Mail />} />
                <Route path="/system-setting/active-license" element={<ActiveLicense />} />
                {/* notification */}
                <Route path="/notification/onSignal" element={<OnSignal />} />
                <Route path="/notification/email" element={<NotifyEmail />} />
                {/* Roll Management */}
                <Route path="/role-user" element={<Roll_user />} />
                <Route path="/editRole/:id" element={<Roll_edit />} />
                <Route path="/role-access" element={<Roll_access />} />
                {/* Theme Market */}
                <Route path="/theme-market" element={<Theme_market />} />
                <Route path="/live-preview/:id" element={<Theme_live_preview />} />
                {/* change Password */}
                <Route path="/change-password" element={<ChangePassword />} />
                {/* Activities */}
                <Route path="/activities" element={<Activities />} />
                {/* general setting */}
                <Route path="/general-setting" element={<General_setting />} />



            </Routes>
        </>
    ) 
}