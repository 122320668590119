import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        invoice_id:654654365436,
        name: "Skipper1",
        email: "skipper1@gmail.com",
        amount:65352,
        date:654352,
        
        status: "created",

    },
    {
        id:2,
        brand: "onhost",
        invoice_id:654654365436,
        name: "Skipper2",
        email: "skipper2@gmail.com",
        amount:65352,
        date:654352,
        status: "pending",

    },
    {
        id:3,
        brand: "onhost",
        invoice_id:654654365436,
        name: "Skipper3",
        email: "skipper3@gmail.com",
        amount:65352,
        date:654352,
        status: "created",
    },
    {
        id:4,
        brand: "onhost",
        invoice_id:654654365436,
        name: "Skipper4",
        email: "skipper4@gmail.com",
        amount:65352,
        date:654352,
        status: "complete",
    },
]
