import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../../components/breadcrumb/index'
import Swal from 'sweetalert2'
export default function MailSetting() {
    const title = "Email Setting"
    const [rerendarApi, setRerendarApi] = useState(false)

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

   

    {/* data receve from store */ }
    useEffect(() => {
        
    //    call api
        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])


    const emailSetting = () => {

        Swal.fire({
            icon: 'info',
            title: 'Are you want to take this action!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        
                    </div>
                    <div className="card-body">
                        <div  className="row">
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Enable Email Notification</ b></label>
                                <select className="form-control">
                                    <option value="on">On</option>
                                    <option value="off">Off</option>

                                </select>
                            </div>
                           
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Complete Payment Notification to Admin</ b></label>
                                <select className="form-control">
                                    <option value="">On</option>
                                    <option value="">Off</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Complete Payment Notification to User</ b></label>
                                <select className="form-control">
                                    <option value="">On</option>
                                    <option value="">Off</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Pending Payment Notification to Admin</ b></label>
                                <select className="form-control">
                                    <option value="">On</option>
                                    <option value="">Off</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Pending Payment Notification to User</ b></label>
                                <select className="form-control">
                                    <option value="">On</option>
                                    <option value="">Off</option>

                                </select>
                            </div>
                            
                        </div>
                        <button onClick={emailSetting} className="btn btn-primary btn-sm mt-3">Update</button>
                    </div>
                </div>
            </div>

        </>
    )
}