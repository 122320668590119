export function apidata(){
    const storeData = [
   
       {
           id:1,
           name:"Skipper",
           user_name: "Skipper1",
           avtar:'https://placehold.co/60x60',
           mobile:'+91 9999 999 999',
           email: "skipper1@gmail.com",
           benificiary_name:'Skipper',
           bank_name:'FYI Bank',
           card:[
            {
                type:"Master Card",
                no: '0000 0000 0000 0000',
                validity:'2024-05',
                cvv:633,
            }, 
            {
                type:"Rupay Card",
                no: '0000 0000 0000 0000',
                validity:'2024-05',
                cvv:633,
            },
            {
                type:"Credit Card",
                no: '0000 0000 0000 0000',
                validity:'2024-05',
                cvv:633,
            }
           ],
           bank_account_no: '99999999999999',
           bank_swiftcode: 'AAAA US 633',
           bank_address: "United State Of America",
           verified: 0,
           ban: 1,
           status: "offline",
           dob:"1-10-23",
       },
       {
           id:2,
           name:"Skipper",
           user_name: "Skipper2",
           avtar:'https://placehold.co/60x60',
           mobile: '+91 9999 999 999',
           email: "skipper2@gmail.com",
           benificiary_name:'Skipper',
           bank_name:'FYI Bank',
           card:[
            {
                type:"master card",
                no: '0000 0000 0000 0000',
                validity:'2024-05',
                cvv:633,
            }, 
            {
                type:"master card",
                no: '0000 0000 0000 0000',
                validity:'2024-05',
                cvv:633,
            }
           ],
           bank_account_no: '99999999999999',
           bank_swiftcode: 'AAAA US 633',
           bank_address: "United State Of America",
           verified: 1,
           ban: 0,
           status: "online",
           dob:"1-10-23",
       },
   
   ]
   return {storeData}
}