import logo from './logo.svg';
import './App.css';
import Layouts from "./layouts/layouts";
import Footer from "./layouts/footer";
import {useState,useMemo} from 'react'
import {AdminRouteq} from './route/index'
function App() {



  return (
    <>
      <Layouts />
      <AdminRouteq  />
      {/* <Footer /> */}
    </>
  );
}

export default App;
