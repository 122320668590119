import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../components/breadcrumb/index'
import { apidata } from './store/store'
import DataTable_Component from '../../components/DataTable/index'
import DataModel from '../../components/userSetting/index'
import VerifyModel from '../../components/userSetting/verify_model/index'


const User_list = () => {

    const { storeData } = apidata()
    // store api data
    const [apistate, setApiState] = useState([])
    // table column state
    const [apicol, setApiCol] = useState([])
    // rendering state
    const [rerendarApi, setRerendarApi] = useState(false)
    // click action button to get table data
    const [SelectVal_details, setSelectVal_details] = useState([])
    const [details_modal_Show, set_details_modal_Show] = useState(false)

    // verify model render controller
    const [verify_modal_Show, set_verify_modal_Show] = useState(false)


    // show all data view modal;
    const modelHandler = (row) => {

        setSelectVal_details(row)
        set_details_modal_Show(true);

    }
    // show verify view modal;
    const verifyModelHandler = (row) => {

        setSelectVal_details(row)
        set_verify_modal_Show(true);

    }

    {/* data receve from store */ }
    useEffect(() => {
        // call api and response data set " setApiData(your res.data) " and column setApiCol( columns )

        setApiState(storeData)
        setApiCol(columns)

    }, [rerendarApi])


    // brad start
    const title = "User List"
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]
    // brad end

    // table column start
    const columns = [
        {
            name: "Avtaar",
            cell: (row) => <><img className='rounded-circle' width='40px' height='40px' src={row.avtar} /></>
        },
        {
            name: "Full Name",
            selector: row => row.user_name,
            sortable: true
        },
        {
            name: "Mobile Number",
            selector: row => row.mobile
        },
        {
            name: "Email",
            selector: row => row.email,
            sortable: true
        },
        {
            name: "Date of Birth",
            selector: row => row.dob,
            sortable: true
        },
        {
            name: "Verified",
            selector: row => <span onClick={() => verifyModelHandler(row)} className={`${row.verified == 1 ? 'btn-success' : 'btn-danger'} btn p-0 px-1 btn-sm `}>{row.verified == 1 ? "true" : "false"}</span>,
            sortable: true
        },
        {
            name: "Status",
            cell: (row) => <><button className={`btn ${row.status == "online" ? 'btn-success' : 'btn-secondary'} btn-sm p-0 px-1`}>{row.status}</button></>

        },
        {
            name: "action",
            cell: (row) => <><button onClick={() => modelHandler(row)} className="btn btn-primary btn-sm">Action</button></>

        }

    ]
    // table column end

    const modelRenderHandle = (val) => {
        set_details_modal_Show(val);

    }
    const verifyModelRenderHandle = (val) => {
        set_verify_modal_Show(val);

    }

    // render page after model change
    const rerendarApiHandler = (e) => {
        setRerendarApi(e)


    }
    return (
        <>
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
               
                {SelectVal_details.length !== 0 && (
                    <>
                        <VerifyModel render={(e) => rerendarApiHandler(e)} row={SelectVal_details} openDetailsModal={verify_modal_Show} modelRender={verifyModelRenderHandle} />
                        <DataModel render={(e) => rerendarApiHandler(e)} row={SelectVal_details} openDetailsModal={details_modal_Show} modelRender={modelRenderHandle} />
                    </>
                )}
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                                <DataTable_Component search="name" apidata={apistate} columns={apicol} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default User_list;