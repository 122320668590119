import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../components/breadcrumb/index'
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faArrowsRetweet,faShuffle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

export default function InvoiceLink() {

    const title = "Invoice Link"
    const [data, setData] = useState([])

    const [rerendarApi, setRerendarApi] = useState(false)

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    {/* data receve from store */ }
    useEffect(() => {
        // call api
        setData("yt5489690456ig485609fgfg4569f84565j4098affg6i0345")

        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])

    const regenerateApi = (row) => {
        Swal.fire({
            icon: 'info',
            title: 'You want to generate new Invoice API KEY!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                
                Swal.fire('Regenerate Success', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const copy_invoice_link = () => {
        navigator.clipboard.writeText(data)
      Swal.fire('Copy success', '', 'success')
    }
    return (
        <>
            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />
                <Link to="/manage-invoice" ><button type="button" className="btn btn-outline-success  btn-sm ">All</button></Link>
                <Link to="/invoice-link" ><button type="button" className="btn btn-outline-primary active btn-sm ms-1 active">Invoice Link</button></Link>
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                                <div  className="input-group mb-3">
                                    <input type="text"  className="form-control Readonly" value={window.location.protocol+'//'+window.location.host+'/invoice/'+data} aria-label="apigenerate" readOnly />
                                    <span  className="input-group-text" onClick={regenerateApi}>
                                        <FontAwesomeIcon icon={faShuffle} />
                                    </span>
                                    <span onClick={copy_invoice_link}  className="input-group-text"><FontAwesomeIcon icon={faCopy} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}