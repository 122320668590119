import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';

export default function Login() {
    const navigate = useNavigate();
    const loginHanle = () => {
        Swal.fire("login success", '', 'success');
        navigate('/');
    }
    
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    return (
        <>
            <div className="container">
                <div className="row justify-content-center align-items-center jumbotron" style={{ height: '65vh' }}>
                    <div className="col-md-5">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header border-bottom text-center">
                                <h2>Login</h2>
                            </div>
                            <div className="card-body">
                                <input type="text" placeholder="Enter Your User Name" className="form-control" />
                                <input type="password" placeholder="Enter Your Password" className="form-control my-3" />
                                <ReCAPTCHA className='mt-4 mb-3'
                                   sitekey={process.env.REACT_APP_SITE_KEY}
                                    onChange={onChange}
                                /> 
                                <button type="sumit" onClick={loginHanle} className="btn btn-primary my-2">Login</button>
                                <Link to="/forgot-password"><p>Forgot Password</p></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}