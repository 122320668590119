import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../../components/breadcrumb/index'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy,faShuffle, } from '@fortawesome/free-solid-svg-icons'




export default function InvoiceLink() {

    const title = "App Setting"
    const [data, setData] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])
    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {
        // call api
        setData("https://pay.dothostbd.com/callback/f7374f13c75d67fbb31c05035f808a9ca75a6daf")
       
        console.log("RENDER FROM STORE app setting")
    }, [rerendarApi])


    const regenerateApi = (row) => {

        Swal.fire({
            icon: 'info',
            title: 'You want to generate new  API KEY!',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                // toast.success('Api regenerate')
                Swal.fire('success', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const copyLink=()=>{
      navigator.clipboard.writeText(data)
      Swal.fire('Copy success', '', 'success')
    }
    
    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    return (
        <>

            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />       
                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                                <div  className="input-group mb-3">
                                    <input type="text"  className="form-control" defaultValue={data}  aria-label="apigenerate" readOnly />
                                    <span  className="input-group-text" onClick={regenerateApi}>
                                    <FontAwesomeIcon icon={faShuffle} />
                                        </span>
                                    <span  className="input-group-text" onClick={copyLink}><FontAwesomeIcon icon={faCopy} /></span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}