import { Link, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/breadcrumb/index';
import { useState } from 'react';
import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBuildingColumns, 
  faCreditCard, 
  faEyeLowVision 
} from '@fortawesome/free-solid-svg-icons';

const BankData = () => {
  const {fields,setFields} = useState({});
  const {userid} = useParams();
    const title = 'Account Details';
    const brad = [
        { name: 'Home' },
        { name: title },
    ];
  return (
    <>
      <div className='container-fluid'>
        <Breadcrumb title={title} brad={brad} />
        <div className='mb-2 my-3'>
            <Link to={`/user/${userid}`}>
                <button type='button' className='btn mt-2 btn-outline-info btn-sm'>User</button>
            </Link>
            <Link to={`/account/${userid}`}>
                <button type='button' className='btn mt-2 btn-outline-success btn-sm ms-1'>Bank</button>
            </Link>
        </div>
        <div className='row'>

        </div>
        <div className='row'>
          <div className='col-lg-3 col-sm-6 col-12 d-flex'>
            <div className='dash-count'>
              <div className='dash-counts'>
                <h4>
                  <CountUp end={100} />
                </h4>
                <h5>Current Balance</h5>
              </div>
              <div className='dash-imgs'>
                <FontAwesomeIcon
                  icon={faCreditCard}
                  size='2xl'
                  style={{ color: 'rgba(0,0,0,.15)' }}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6 col-12 d-flex'>
            <div className='dash-count das1'>
              <div className='dash-counts'>
                <h4>
                  <CountUp end={130} />
                </h4>

                <h5>Panding Payments</h5>
              </div>
              <div className='dash-imgs'>
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size='lg'
                  style={{ color: 'rgba(0,0,0,.15)' }}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6 col-12 d-flex'>
            <div className='dash-count das2'>
              <div className='dash-counts'>
                <h4>
                  <CountUp end={160} />
                </h4>

                <h5>Bank Payments</h5>
              </div>
              <div className='dash-imgs'>
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  size='lg'
                  style={{ color: 'rgba(0,0,0,0.15)' }}
                />
              </div>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6 col-12 d-flex'>
            <div className='dash-count das3'>
              <div className='dash-counts'>
                <h4>
                  <CountUp end={90} />
                </h4>

                <h5>Pending Bank Payments</h5>
              </div>
              <div className='dash-imgs'>
                <FontAwesomeIcon
                  icon={faEyeLowVision}
                  size='lg'
                  style={{ color: 'rgba(0,0,0,.15)' }}
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className='card' style={{ borderTop: '2px solid #4723d9' }}>
                    <div className='card-header d-flex justify-content-between border-bottom pb-1'>
                        <h4>Add Money</h4>
                        <button className='btn' style={{ background: '#4723d9', color: '#fff' }}>Add +</button>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-md-12 my-3 text-end'>
                            </div>
                        </div>
                    </div>
                </div>
      </div>
    </>
  )
}

export default BankData