export const apidata = [

    {
        id:1,
        brand:"dothost",
        name: "Skipper1",
        email: "skipper1@gmail.com",
        status: "complete",
        invoice_id: 12,
        payment_method:"okWallet",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:23,
        payment_type: "invoice",
        reference: "payments"

    },
    {
        id:2,
        brand:"dothost",
        name: "Skipper2",
        email: "skipper2@gmail.com",
        status: "refunded",
        invoice_id: 12,
        payment_method:"okWallet",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:13,
        payment_type: "invoice",
        reference: "payments"
    },
    {
        id:3,
        brand:"dothost",
        name: "Skipper3",
        email: "skipper3@gmail.com",
        status: "pending",
        invoice_id: 12,
        payment_method:"okWallet",
        sender_number: 133,
        transaction_id: 563456,
        amount: 32432,
        date:"1-10-23",
        fee: 4000,
        total:3423,
        refunded_amount:43,
        payment_type: "invoice",
        reference: "payments"
    },
    
]
