import { useState, useEffect, useRef } from 'react'
import Swal from 'sweetalert2'
import Breadcrumb from '../../components/breadcrumb/index'
import Logo from '../../assets/photo/admin.jpg'
import {useNavigate} from 'react-router-dom'

export default function Add_Faq() {
    const title = "Add Faq"
    const navigate=useNavigate()
    const[rerendarApi,setRerendarApi]=useState(false)

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]

    const add_faq=()=>{
        // call api
        Swal.fire({
            icon:'warning',
            title: 'You wont be able to revert this!',       
            showCancelButton: true,
            confirmButtonText: 'Yes',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            setRerendarApi(!rerendarApi)
            if (result.isConfirmed) {
              Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })
    }

    return (
        <>

            <div  className="container-fluid">
                <Breadcrumb title={title} brad={brad} />

                <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                    <div className="card-header d-flex justify-content-between border-bottom pb-1">
                        <div className="">{title} </div>
                        <div className="btn btn-info btn-sm" onClick={()=>navigate(-1)}>Back</div>
                        
                    </div>
                    <div className="card-body">
                        <div  className="row ">
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>select brand</ b></label>
                                <select className="form-control">
                                    <option value="">onhost</option>
                                    <option value="">onhost2</option>

                                </select>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>English Title</ b></label>
                                <input type="text" placeholder="English Title" className="form-control" />
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Bangla Title</ b></label>
                                <input type="text" placeholder="Bangla Title" className="form-control" />
                            </div>
                            
                            <div  className="col-12 mt-3">
                                <label for="brand"><b>English content</ b></label>
                                <textarea type="text" placeholder="English content" rows="5" className="form-control" ></textarea>
                            </div>
                            <div  className="col-12 mt-3">
                                <label for="brand"><b>Bangla Content</ b></label>
                                <textarea type="text" placeholder="Bangla Content" rows="5" className="form-control" ></textarea>
                            </div>
                            <div  className="col-md-6 mt-3">
                                <label for="brand"><b>Status</ b></label>
                                <select className="form-control">
                                    <option value="">Active</option>
                                    <option value="">Pending</option>

                                </select>
                            </div>
                        </div>
                        <button onClick={add_faq} className="btn btn-primary btn-sm mt-4">Add Faq</button>
                    </div>
                </div>
            </div>

        </>
    )
}