import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        transaction_id: 1256,
        status: "active",
        payment_method:"okWallet",
        sender_number: 13243,
        amount: 32432,
        date:"1-10-23",
        main_balence:687,

    },
    {
        id:2,
        
        transaction_id: 563456,
        status: "pending",
        payment_method:"okWallet",
        sender_number: 133676,
        amount: 32432,
        date:"1-10-23",
        main_balence:687,

    },
    {
        id:3,
        transaction_id: 7956,
        status: "trash",
        payment_method:"okWallet",
        sender_number: 13543,
        amount: 32432,
        date:"1-10-23",
        main_balence:687,
    },

  

]
