import { useState, useEffect, useRef } from 'react'
import Breadcrumb from '../../../components/breadcrumb/index'
import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faArrowsRetweet, } from '@fortawesome/free-solid-svg-icons'

// import {toast} from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
// import {Modal} from 'bootstrap'



export default function InvoiceLink() {

    const title = "Templete Setting"
    const [apistate, setApiState] = useState([])
    {/* data table column name */ }
    const [apicol, setApiCol] = useState([])

    const brad = [
        {
            name: "home",
        },
        {
            name: title,
        }
    ]



    const [rerendarApi, setRerendarApi] = useState(false)

    {/* data receve from store */ }
    useEffect(() => {
    //   call api

        console.log("RENDER FROM STORE TRASH")
    }, [rerendarApi])


    const update_templete = () => {

        Swal.fire({
            icon: 'info',
            title: 'Are You Want to take this action',
            showCancelButton: true,
            confirmButtonText: 'Yes',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRerendarApi(!rerendarApi)
                // toast.success('Api regenerate')
                Swal.fire('Saved!. id:', '', 'success')
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }
    const copyCode=()=>{
        Swal.fire('code copy success', '', 'success')
    }


    return (
        <>


            <div className="container-fluid" >
                <Breadcrumb title={title} brad={brad} />

                <div className="row my-3">
                    <div className="col-12">
                        <div className="card" style={{ borderTop: "2px solid #4723d9" }}>
                            <div className="card-header d-flex justify-content-between border-bottom pb-1">
                                <h4>{title}</h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div  className="col-12 mt-3">
                                        <label>Bangla Templete</label>
                                        <textarea className="form-control" rows="6"></textarea>
                                    </div>
                                    <div  className="col-12 mt-3">
                                        <label>English Templete</label>
                                        <textarea className="form-control" rows="6"></textarea>
                                    </div>
                                </div>
                                <button onClick={update_templete} className="btn btn-primary btn-sm mt-2">Update</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="card">
                            <div className="card-header">
                                Templete Short Codes
                            </div>
                            <div className="card-body">
                            <div className="table-responsive my-3">
                            <table className="table align-middle border table-striped table-hover">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Information</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>d23 </td>
                                        <td>dd3d</td>

                                    </tr>
                                    <tr>
                                        <td>d23 </td>
                                        <td>dd3d</td>

                                    </tr>
                                    <tr>
                                        <td>d23 </td>
                                        <td>dd3d</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}