import { useState, useEffect } from 'react'

export const apidata = [

    {
        id:1,
        brand: "onhost",
        log: "de2",
        amounts:34,
        invoice_id:654654365436,
        email: "bkash",
        amount:65352,
        date:654352,
        description:"fgrgge",
        status: "created",

    },
    {
        id:2,
        brand: "onhost2",
        log: "dedrd2",
        amounts:374,
        invoice_id:65464365436,
        email: "bkash",
        amount:6552,
        date:65352,
        description:"fgdsfsdrgge",
        status: "pending",

    },
   
  

]
